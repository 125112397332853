import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { arrowRight } from "../../../Base/SVG";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { pieGraph } from "../../../service/user";
ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export default function Activity() {
  const [dataset, setDatasets] = useState([]);

  const pieData = async () => {
    try {
      const pieGraphData = await pieGraph();
      if (pieGraphData?.data?.success) {
        // Round off the percentage values to two decimal places
        const roundedData = pieGraphData?.data?.data?.map((dataPoint) => ({
          ...dataPoint,
          percentage: parseFloat(dataPoint.percentage).toFixed(2),
        }));
        setDatasets(roundedData ?? []);
      } else {
        console.error("Error fetching pie graph data:", pieGraphData.message);
      }
    } catch (error) {
      console.error("Error fetching pie graph data:", error);
    }
  };

  useEffect(() => {
    pieData();
  }, []);

  const data = {
    labels: dataset?.map((dataPoint) => dataPoint.brand),
    datasets: [
      {
        data: dataset?.map((dataPoint) => dataPoint.percentage),
        backgroundColor: dataset?.map((dataPoint) =>
          dataPoint.brand === "Visa" ? "#D70067" : "#0177FB"
        ),
      },
    ],
  };

  return (
    <div className="overviewSide__act">
      <h4>Activity</h4>
      <div className="overviewSide__act-progress" style={{ height: "150px" }}>
        {dataset.length !== 0 ? (
          <>
            <div className="doughnut">
              <Doughnut options={options} data={data} />
            </div>
            <div className="legends">
              {dataset.map((dataPoint, index) => (
                <div className="legends__item" key={index}>
                  <span
                    style={{
                      background:
                        dataPoint.brand === "Visa" ? "#D70067" : "#0177FB",
                    }}
                  ></span>
                  <div className="legends__item-content">
                    <small>{dataPoint.brand}</small>
                    {dataPoint.percentage}%
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "25px",
              textAlign: "center", color:"grey"
            }}
          >
            Card Utilization
          </div>
        )}
      </div>
      <Link to="/" className="button bordered">
        View all activity {arrowRight}
      </Link>
    </div>
  );
}