import React, { useEffect, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { eye, eyeCross } from "../Base/SVG";
import useMediaQuery from "../Hooks/useMediaQuery";
import { login,googleLogin,verifyGoogleLogin } from "../service/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SignIn({ setIsAuth }) {
  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false);
  const [error, setError] = useState(false);
  const [mobImage, setMobImage] = useState(false);
  const width = useMediaQuery();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

 

  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  const btnClick = async () => {
    if (form.email === "" || form.password === "") setError(true);
    else {
      setError(false);
      try {
        let payload = {
          email: form.email,
          password: form.password,
        };
        let { data } = await login(payload);
        console.log('data: ', data);

        // when verified
        if (data?.success) {
          // localStorage.setItem("token", data?.data?.AccessToken);
          // setIsAuth(true);
          navigate("/otp", {
            state: {
              email: form.email,
              password: form.password,
            },
          });
        } else {
          toast.error("Invalid email or password. Retry!");
        }
      } catch (error) {
        setError(true);
        console.log(error);
      }
    }
  };

  const handleGoogle = async() =>{
     try{
      let {data} = await googleLogin();
     
      
      if(data?.success){
        console.log('Google redirect successful')
        window.location.href = data.data
      } else{
        toast.error('Google redirect failed!')
      }
     }catch(err){
      setError(true);
      console.log(err);
     }
  };

  const handleVerifyGoogleLogin = async () => {
    const url = window.location.href;
    const google_sign = url.includes("google_sign");
    const googleSignIn = url.split("google_sign=")[1];

    if (google_sign) {
      const response = await verifyGoogleLogin(googleSignIn);
      console.log("google login: ",response)
      if (response) {
        console.log("Login Success");
        localStorage.setItem("token", response.AccessToken);
        setIsAuth(true);
        navigate('/')
      }
    }

  };

  useEffect(() => {
    if (width <= 1024) {
      setMobImage(true);
    } else setMobImage(false);
  }, [width]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAuth(true);
    }
    handleVerifyGoogleLogin();
  }, []);
  return (
    <div className="auth">
      <ToastContainer/>
      <div className="auth__inner">
        <div className="auth__content">
          <div className="auth__content-inner">
            <div className="auth__content-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </div>
            <h3>Welcome Back </h3>
            <div className="authForm">
              <div className="input__outer">
                <label htmlFor="">Email or username</label>
                <div
                  className={
                    "input " + (form.email === "" && error ? "error" : "")
                  }
                >
                  <input
                    type="email"
                    placeholder="Email or username"
                    value={form.email}
                    onChange={onChangeInput("email")}
                  />
                  {form.email === "" && error && (
                    <span className="error">Please enter your email</span>
                  )}
                </div>
              </div>{" "}
              <div className="input__outer">
                <label htmlFor="">
                  Password <Link to="/forgot-password">Forgot Password?</Link>
                </label>
                <div
                  className={
                    "input pass " +
                    (form.password === "" && error ? "error" : "")
                  }
                >
                  <input
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    value={form.password}
                    onChange={onChangeInput("password")}
                  />
                  {form.password === "" && error && (
                    <span className="error">Please enter your password</span>
                  )}
                  <button
                    className="inputEye"
                    type="button"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? eyeCross : eye}
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="button submit"
                onClick={btnClick}
              >
                Sign In
              </button>
              <div className="authForm__foot">
                <p>
                  You dont Have an Account? <Link to="/sign-up">Sign Up</Link>
                </p>
              </div>
              <button
                type="button"
                className="button google-login-btn"
                onClick={handleGoogle}
              >
                
               Sign in with Google
              </button>
            </div>
          </div>
        </div>
        <div className="auth__image">
          {mobImage ? (
            <img
              src={process.env.PUBLIC_URL + "/images/signInMob.png"}
              alt="signInMob"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/signIn.jpg"}
              alt="signIn"
            />
          )}
        </div>
      </div>
    </div>
  );
}

