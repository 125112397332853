import React, { useEffect, useState } from "react";
import useMediaQuery from "../Hooks/useMediaQuery";
import { forgot } from "../service/auth";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


export default function ForgotPassword({ setIsAuth }) {
  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [mobImage, setMobImage] = useState(false);
  const width = useMediaQuery();

  const [form, setForm] = useState({
    email: "",
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAuth(false);
    }
  }, []);

  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };

  const btnClick = async () => {
    if (form.email === "") setError(true);
    else {
      setError(false);
      try {
        let payload = {
          email: form.email,
        };
        let response = await forgot(payload);

        // when verified
        console.log('data?.success: ', response?.data?.success);
        if (response?.data?.success) {
          setSuccessMessage(
            "Reset link has been sent to your email successfully."
          );
        } else {
          console.log(
            'Error'
          )
          toast.error(
            `Invalid email. Retry!`
          )
        }
      } catch (error) {
        setError(true);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (width <= 1024) {
      setMobImage(true);
    } else setMobImage(false);
  }, [width]);

  return (
    <div className="auth">
    <ToastContainer/>
      <div className="auth__inner">
        <div className="auth__content">
          <div className="auth__content-inner">
            <Link to='/'>
            <div className="auth__content-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/logo.svg"}
                alt="logo"
              />
            </div>
            </Link>
            {successMessage ? (
              <h3>{successMessage}</h3>
            ) : (
              <h3>Forgot Password?</h3>
            )}
            <div className="authForm">
              {!successMessage && (
                <div className="input__outer">
                  <label htmlFor="">Email</label>
                  <div
                    className={
                      "input " + (form.email === "" && error ? "error" : "")
                    }
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                    {form.email === "" && error && (
                      <span className="error">Please enter your email</span>
                    )}
                  </div>
                </div>
              )}
              {!successMessage && (
                <button
                  type="button"
                  className="button submit"
                  onClick={btnClick}
                >
                  Continue
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="auth__image">
          {mobImage ? (
            <img
              src={process.env.PUBLIC_URL + "/images/signInMob.png"}
              alt="signInMob"
            />
          ) : (
            <img
              src={process.env.PUBLIC_URL + "/images/signIn.jpg"}
              alt="signIn"
            />
          )}
        </div>
      </div>
    </div>
  );
}

