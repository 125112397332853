import React from "react";
import CustomSelect from "./CustomSelect";
import DateInput from "./DateInput";
import { ToWords } from "to-words";
import { Switch } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
const reasonList = [
  {
    id: "1",
    value: "Rental",
  },
  {
    id: "2",
    value: "Mortgage",
  },
  {
    id: "3",
    value: "Others",
  },
];
const deliveryList = [
  {
    id: "1",
    value: "Cheque Delivery",
    expect: "Expect in 2-4 Business Days",
  },
  {
    id: "2",
    value: "e-interac",
    expect: "Expect in 24 hrs",
  },
  {
    id: "3",
    value: "ACH Bank Transfer (Wire)",
    expect: "Expect in 3-5 Business Days",
  },
];
export default function Pay({ onChangeInput, updatePos, form, pos, error }) {
  const changePay = (item) => {
    updatePos({
      pay: item.value,
      payee_id: item.stripe_account_id,
    });
  };
  const changeReason = (item) => {
    updatePos({ memo_reason: item });
  };
  const [showrecurringDate, setShowRecurringDate] = React.useState(false);
  const handleRecurringPayment = (e) => {
    updatePos({
      IsRecurringPayment: e.target.checked,
    });

    setShowRecurringDate(e.target.checked);
  };

  const changeDeliveryType = (item) => {
    updatePos({ payment_type: item });
    let payQueFee = 0;
    if (
      item.value === "e-interac" ||
      item.value === "ACH Bank Transfer (Wire)"
    ) {
      payQueFee = 4.4;
    } else if (item.value === "Cheque Delivery") {
      payQueFee = 4.8;
    }
    updatePos({
      fees: (parseFloat(pos.amount) * payQueFee) / 100 + 0.3 || 0,
    });
  };
  const deliveryListIndex = React.useMemo(() => {
    if (pos.payment_type.value !== "") {
      return deliveryList.findIndex(
        (item) => item.value === pos.payment_type.value
      );
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const reasonListIndex = React.useMemo(() => {
    if (pos.memo_reason.value !== "") {
      return reasonList.findIndex(
        (item) => item.value === pos.memo_reason.value
      );
    }
    return null;
    // eslint-disable-next-line
  }, [pos]);
  const toWords = new ToWords();

  return (
    <div className="formm">
      <div className="formm__inner">
        <div className="input__outer">
          <label htmlFor="">Pay</label>
          <CustomSelect
            value={pos.pay}
            error={error}
            onChange={changePay}
            list={form.companies.map((item) => {
              return { ...item, value: item.paying_for };
            })}
            selected="-----"
          // selected={{
          //   ...form.companies[companiesIndex],
          //   value: form.companies[companiesIndex]?.paying_for,
          // }}
          />
        </div>
        <div className="input__outer">
          <label htmlFor="">Amount</label>
          <div
            className={"input " + (error && pos.amount === "" ? "error" : "")}
          >
            <input
              value={pos.amount}
              onChange={(e) => {
                let words;
                if (e.target.value === "") {
                  words = "";
                } else {
                  words = toWords.convert(parseFloat(e.target.value));
                }
                updatePos({
                  amount: e.target.value
                    .replace(/[^.\d]/g, "")
                    .replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2"),
                  amount_words: words,
                });
                let payQueFee = 0;
                if (
                  pos.payment_type.value === "e-interac" ||
                  pos.payment_type.value === "ACH Bank Transfer (Wire)"
                ) {
                  payQueFee = 4.4;
                } else if (pos.payment_type.value === "Cheque Delivery") {
                  payQueFee = 4.8;
                }
                updatePos({
                  fees:
                    (parseFloat(e.target.value) * payQueFee) / 100 + 0.3 || 0,
                });
              }}
              type="number"
              placeholder="e.g $1,234.00"
            />
            {error && pos.amount === "" && (
              <span className="errorM">This field required</span>
            )}
            <div className="inputLab">CAD</div>
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Amount in words</label>
          <div
            className={
              "input " + (error && pos.amount_words === "" ? "error" : "")
            }
          >
            <input
              value={pos.amount_words}
              onChange={(e) => {
                updatePos({
                  amount_words: e.target.value.replace(/[^a-zA-Z]/g, ""),
                });
              }}
              type="text"
              placeholder="e.g Thirteen hundred and fifty"
            />
            {error && pos.amount_words === "" && (
              <span className="errorM">This field required</span>
            )}
          </div>
        </div>
        <div className="input__outer">
          <label htmlFor="">Delivery date</label>
          <DateInput
            placeholder="Delivery Date"
            updatePos={updatePos}
            error={error}
            pos={pos}
            type="delivery_date"
          />
        </div>
        <div className="input__outer">
          <label htmlFor="">Make this a recurring payment</label>
          <Switch
            onChange={handleRecurringPayment}
            defaultChecked={pos.IsRecurringPayment}
          />
        </div>
        {showrecurringDate && (
          <div className="input__outer">
            <label htmlFor="">Stop this on: </label>
            <div className={"input inputDate "}>
              <DatePicker
                required={showrecurringDate}
                selected={pos?.ReccuringStopDate}
                onChange={(date) => updatePos({ ReccuringStopDate: date })}
                startDate={pos?.ReccuringStopDate}
                placeholderText="Stop Date:"
              />
            </div>
          </div>
        )}

        <div className="formm__double">
          <div className="input__outer sm">
            <label htmlFor="">Memo</label>
            <CustomSelect
              value={pos.memo_reason}
              error={error}
              onChange={changeReason}
              list={reasonList}
              selected={reasonList[reasonListIndex]}
            />
          </div>
          <div className="input__outer big">
            <label htmlFor=""></label>
            <div
              className={
                "input " + (error && pos.memo_text === "" ? "error" : "")
              }
            >
              <input
                value={pos.memo_text}
                onChange={onChangeInput("memo_text")}
                type="text"
                placeholder="e.g Paying For Goods And Services"
              />
              {error && pos.memo_text === "" && (
                <span className="errorM">This field required</span>
              )}
            </div>
          </div>
        </div>
        {/* <div className="input__outer">
            <label htmlFor="">Description</label>
            <div
              className={
                "input " + (error && pos.description === "" ? "error" : "")
              }
            >
              <input
                value={pos.description}
                onChange={onChangeInput("description")}
                type="text"
                placeholder="e.g Paying For Goods And Services"
              />
              {error && pos.memo_text === "" && (
                <span className="errorM">This field required</span>
              )}
            </div>
        </div> */}
        <div className="input__outer">
          <label htmlFor="">Select Delivery Type</label>
          <CustomSelect
            value={pos.payment_type}
            error={error}
            onChange={changeDeliveryType}
            list={deliveryList}
            selected={deliveryList[deliveryListIndex]}
          />
        </div>
      </div>
    </div>
  );
}
